import React from 'react'
import ErrorsData from './ErrorsData'

function Erros() {
    return (
        <div>
            <ErrorsData />
        </div>        
    )
}

export default Erros