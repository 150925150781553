import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import add from '../../../images/add.svg'
import { makeStyles } from "@mui/styles";
import { Container } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import av4 from '../../../images/av4.svg'

import MemberMenu from './Membermenu';
import Toast from '../../Common/Toast';
const usestyles = makeStyles((theme) => ({
    CreateTeam: {
        background: '#3330E4 !important',
        width: '164px !important',
        height: '40px !important'
    },
    addMemeber: {
        background: '#3330E4 !important',
        width: '138px !important',
        height: '36px !important',
        zIndex: 1
    },
    addProject: {
        display: 'flex !important',
        gap: '6px'
    },
    buttonText: {
        fontSize: '14px !important',
        color: '#fff',
        textTransform: "initial !important",
        fontFamily: 'poppins !important'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        background: "#EBEBEB",
        padding: "20px"
    },
    headerText: {
        fontFamily: 'Poppins !important',
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        fontSize: " 20px !important",
        lineHeight: "30px !important",
        color: "#06283D !important",

    },
    CloseIcon: {
        color: 'white',
        width: '38px',
        height: '38px',
        borderRadius: '50%',
        background: '#3330E4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center"
    },
    modalBody: {
        padding: '20px 0'
    },
    content: {
        marginTop: '20px'
    },
    parent: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '10px'
    },
    left: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px'
    },
    btnText: {
        fontFamily: 'Poppins !important',
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "24px !important",
        /* identical to box height */

        textTransform: 'capitalize !important',
        color: "#E43030 !important",
    },
    text: {
        fontFamily: 'Poppins !important',
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        fontSize: "14px !important",
        lineHeight: "24px !important",

        color: "#000000 !important",
    }
}))

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: '#fff',
    // bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,

};

export default function TeamModel({ closeModal }) {

    const classes = usestyles()
    const [open, setOpen] = React.useState(false);
    const data = [
        { id: 1, img: av4, name: "Lorem Ipsum", text: 'remove' },
        { id: 2, img: av4, name: "Lorem Ipsum", text: 'remove' },
        { id: 3, img: av4, name: "Lorem Ipsum", text: 'remove' },
        { id: 4, img: av4, name: "Lorem Ipsum", text: 'remove' },
        { id: 5, img: av4, name: "Lorem Ipsum", text: 'remove' },
        { id: 6, img: av4, name: "Lorem Ipsum", text: 'remove' },
        { id: 7, img: av4, name: "Lorem Ipsum", text: 'remove' },
        { id: 8, img: av4, name: "Lorem Ipsum", text: 'remove' },

    ];
    const [listOfItems, setListOfItems] = React.useState(data);

    function handleRemove(id) {
        const newList = listOfItems.filter((item) => item.id !== id);
        setListOfItems(newList);
        setOpen(true)
    }
    return (
        <div>

            <Modal
                open={true}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={classes.modal}>
                        <div className={classes.header}>
                            <Typography className={classes.headerText}>Lorem Ipsum</Typography>
                            <div className={classes.CloseIcon}
                                // onClick={handleClose}
                                onClick={() => closeModal(false)}
                            ><CloseIcon /></div>
                        </div>
                        <Container>
                            <div className={classes.modalBody}>
                                <MemberMenu />

                                <div className={classes.content}>
                                    {listOfItems.map((member, index) => (
                                        <>
                                            <div className={classes.parent} key={index}>
                                                <div className={classes.left}>
                                                    <img src={member.img} />
                                                    <Typography className={classes.text}>{member.name}</Typography>
                                                </div>
                                                <div>
                                                    <Button className={classes.btnText} onClick={() => handleRemove(member.id)}>{member.text}</Button>
                                                </div>
                                            </div>

                                        </>
                                    ))}
                                </div>
                            </div>

                        </Container>
                    </div>
                </Box>
            </Modal>
            <Toast msg="Member Removed..." handleClose={()=>setOpen(false)} open={open} type="success" />
        </div>
    );
}
