import './App.css';
import Drawer from "./component/Drawer";
import { createTheme, ThemeProvider } from "@mui/material";
import Dashboard from "./component/Dashboard/Dashboard";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Errors from './component/Errors/Errors'
import Users from './component/Users/Users';
import Pricing from './component/Pricing/Pricing';
import CreateTeamForm from './component/Team/CreateTeamForm';
import Team from './component/Team/Team';
import CreatePlan from './component/Pricing/CreatePlan';
import NewNotification from './component/NewNotification';


function App() {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <Router basename={'/'}>
        <Drawer>
          <Routes>
            <Route path={`/`} element={<Dashboard />} />
            <Route path={`/errors`} element={<Errors />} />
            <Route path={`/Project`} element={<Users />} />
            <Route path={`/Teams`} element={<Team />} />
            <Route path={`/Pricing`} element={<Pricing />} />
            <Route path={`/CreateTeamForm`} element={<CreateTeamForm />} />
            <Route path={`/CreatePlan`} element={<CreatePlan />} />
            <Route path={`/NewNotification`} element={<NewNotification />} />
            <Route />
          </Routes>
        </Drawer>
      </Router>
    </ThemeProvider>

  );
}

export default App;
