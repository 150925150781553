import React from 'react'
import CreateTeam from './CreateTeam'
import CreateTeamForm from './CreateTeamForm'
import TeamPagination from './TeamPagination'
import TablePagitionAction from '../pagination/TablePaginationActions'


function Team() {
  return (
    <div>

      <CreateTeam />
    {/* <TeamPagination/> */}
    {/* <TablePagitionAction/> */}
  
    </div>
  )
}

export default Team