import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TeamData from '../../json/teamData.json';
import { makeStyles } from "@mui/styles";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import av1 from '../../images/av1.svg'
import av2 from '../../images/av2.svg'
import av3 from '../../images/av3.svg'
import av4 from '../../images/av4.svg'
import Team from '../Team/Team';
import TableHead from '@mui/material/TableHead';
import TeamModel from '../Users/modals/TeamModel';
import Toastify from '../Toastify/Toastify';
const usestyles = makeStyles((theme) => ({
    tableHead: {
        background: '#F7F7F7 !important',
        fontFamily: ' Poppins!important ',
        fontStyle: " normal !important",
        fontWeight: " 600 !important",
        fontSize: "  18px!important",
        lineHeight: "27px !important ",
        color: " #06283D!important ",
    },
    tableRow: {
        borderBottom: '1ix solid #D9D9D9 !important'
    },
    errorTable: {
        fontFamily: 'poppins!important',
        marginTop: '30px!important'
    },
    tableData: {
        // padding: '14px 10px!important',
        fontSize: '13px!important',
        color: '#404446!important',
        borderBottom: " 1px solid #D9D9D9!important",
        fontFamily: 'poppins!important',
        textAlign:'left !important'
    },
    tablAV: {
        // padding: '14px 10px!important',
        // borderBottom: " 1px solid #D9D9D9!important",
    }
}))
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};




export default function CustomPaginationActionsTable(props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TeamData.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const classes = usestyles()
    const [openModal, setOpenModal] = React.useState(false);

    const filteredData = TeamData.filter((e) => {

        //if no input the return the original
        if (props.input === '') {
            return e;
        }
        //return the item which contains the user input
        else {
            return e.name.toString().toLowerCase().includes(props.input) || e.details.toString().toLowerCase().includes(props.input) || e.id.toString().toLowerCase().includes(props.input) || e.date.toString().toLowerCase().includes(props.input)

        }
    })

    return (
        <TableContainer component={Paper}  >
            <Table aria-label="custom pagination table">
                <TableHead>
                    <TableRow className={classes.tableHead} >
                        <TableCell className={classes.tableHead}>ID</TableCell>
                        <TableCell className={classes.tableHead}>Team Name</TableCell>
                        <TableCell className={classes.tableHead}>Status Details</TableCell>
                        <TableCell className={classes.tableHead} style={{paddingLeft:'50px'}}>Team Member</TableCell>
                        <TableCell className={classes.tableHead}>Creation Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : TeamData
                    ).map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row" className={classes.tableData}>
                                {row.id}
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.tableData}>
                                {row.details}
                            </TableCell>
                            <TableCell align="right" className={classes.tableData}>
                                {row.name}
                            </TableCell>
                            <TableCell align="right" className={classes.tableData}>
                                <AvatarGroup className={classes.tablAV}>
                                    <Avatar alt="Remy Sharp" src={av1} onClick={() => setOpenModal(!openModal)} />
                                    <Avatar alt="Travis Howard" src={av2} onClick={() => setOpenModal(!openModal)} />
                                    <Avatar alt="Cindy Baker" src={av3} onClick={() => setOpenModal(!openModal)} />
                                    <Avatar alt="Agnes Walker" src={av4} onClick={() => setOpenModal(!openModal)} />
                                    <Avatar alt="Trevor Henderson" src={av4} onClick={() => setOpenModal(!openModal)} />
                                </AvatarGroup>
                            </TableCell>
                            <TableCell align="right" className={classes.tableData}>
                                {row.date}
                            </TableCell>
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                           
                            count={TeamData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
            {openModal && <TeamModel closeModal={setOpenModal} />}
           { Toastify('Action successfully executed!', 'success')}
        </TableContainer>
    );
}
