import React from "react";
import ProjectCardItems from "../Projects/ProjectCardItems";
import { makeStyles } from "@mui/styles";
import { Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CardItems from "./CardItems";

const useStyle = makeStyles((theme) => ({
  projectbody: {
    width: "100%",
    boxShadow: "0px 2px 2px #80808059",
    marginTop: "20px",
    borderRadius: "6px",
    marginLeft:'10px',
    paddingBottom: "20px"
  },
  title: {
    fontSize: "16px !important",
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
    margin: "15px !important",
    paddingTop: "15px",
  },
}));

const AllProjects = () => {
  const classes = useStyle();
  return (
    <div className={classes.projectbody}>
      <Typography className={classes.title}>All Projects</Typography>
      {/* <div className={classes.allprojectbody}> */}
      <CardItems />
      {/* </div> */}
    </div>
  );
};

export default AllProjects;
