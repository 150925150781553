import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import darkadd from '../../../images/darkadd.svg';
import add from '../../../images/add.svg';
import close from '../../../images/close.svg';
import avblue from '../../../images/avblue.svg'
import Typography from '@mui/material/Typography';
import { makeStyles } from "@mui/styles";
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const usestyles = makeStyles((theme) => ({
  addMemeber: {
    background: '#3330E4 !important',
    width: '138px !important',
    height: '36px !important',
    zIndex: 1
  },
  addProject: {
    display: 'flex !important',
    gap: '6px'
  },
  buttonText: {
    fontSize: '14px !important',
    color: '#fff',
    textTransform: "initial !important",
    fontFamily: 'poppins !important'
  },
  text: {
    fontFamily: 'Poppins !important',
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "24px !important",
    color: "#000000 !important",
    border:'none',
    outline:'none',
    background:'transparent',
    width:'100%'
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px'
  },
  top: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '5px',
    paddingBottom:"5px"

  },
  parent: {
    display: 'flex',
    justifyContent: 'center',
    // paddingBottom: '10px',
    padding:'5px',
    width:'175px'
  },
  done: {
    fontFamily: 'Poppins !important',
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    lineHeight: "18px !important",
    color: "#3330E4 !important",
    textTransform: "initial !important",
  },
  icnImg: {
    width: '20px'
  }
}))

export default function MemberMenu() {
  const classes = usestyles()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const data = [
    { id: 1, img: avblue, name: "Lorem Ipsum", icon: darkadd },
    { id: 2, img: avblue, name: "Lorem Ipsum", icon: darkadd },
    { id: 3, img: avblue, name: "Lorem Ipsum", icon: darkadd },
    { id: 4, img: avblue, name: "Lorem Ipsum", icon: darkadd },
    { id: 5, img: avblue, name: "Lorem Ipsum", icon: darkadd },
    { id: 6, img: avblue, name: "Lorem Ipsum", icon: darkadd },
    { id: 7, img: avblue, name: "Lorem Ipsum", icon: darkadd },
    { id: 8, img: avblue, name: "Lorem Ipsum", icon: darkadd },
  ];
  const [list, setList] = useState(data);
  const [name, setName] = useState('aaa');
  function handleChange(e) {
    setName(e.target.value)
    console.log("change name");
  }

  function handleAdd() {
    const newList = list.concat({ id: data.length + 1, img: avblue, name: "Lorem Ipsum", icon: darkadd  });

    setList(newList);

    setName('');
    console.log("add item", newList)
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={classes.addMemeber}
      >
        <div className={classes.addProject}>
          <img src={add} />
          <Typography className={classes.buttonText}
          >Add Member</Typography>
        </div>

      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem>
         
        </MenuItem>
        <Typography>
        <div className={classes.content}>
            <div className={classes.top}>
              <div className={classes.left}>
                <img src={avblue} className={classes.icnImg} />
                <Typography className={classes.text}>Lorem Ipsum</Typography>
                <img src={close} />
              </div>
              <div>
                <Button className={classes.done} onClick={handleClose}>Done</Button>
              </div>
            </div>

          </div>
          <div>
            {list.map((member) => (
              <>
                <div className={classes.parent}>

                  <div className={classes.left}>
                    <img src={member.img} />
                    {/* <Typography className={classes.text} key={member.id}>{member.name}</Typography> */}
                    <input type='text' value={member.name} key={member.id} className={classes.text} onChange={handleChange}/>
                  </div>
                  <div>
                    <img src={member.icon} onClick={handleAdd}/>
                  </div>
                </div>

              </>
            ))}
          </div>
        </Typography>
      </Menu>
    </div>
  );
}
