import React from "react";
import { makeStyles } from "@mui/styles";
import fileIcon from "../../../images/projectimg.svg";
import { Container, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { useCallback } from "react";
import UserModal from "../modals/UserModal";

const useStyle = makeStyles((theme) => ({
  cardbody: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: '100%',

    // margin: "12px 12px 15px 12px",
  },
  fileiconstyle: {
    width: "22px",
  },
  title: {
    fontSize: "14px !important",
    fontFamily: "Poppins !important",
    fontWeight: "600 !important",
    margin: 'auto !important'
  },
  starIcon: {
    position: 'absolute',
    top: '2px',
    right: '5px',
    width: '18px !important'
  }
}));
const ProjectCardItems = ({ text }) => {
  const classes = useStyle();
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);
  const onHandleClick = useCallback((open, title) => {
      setOpen(open);
      setTitle(title);
      console.log("open")
  })
  return (
    <>
      <Card className={classes.cardbody}  onClick={() => onHandleClick(true, text)}>
        <CardActionArea>
          <CardContent>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",

              }}
            >
              <img src={fileIcon} className={classes.fileiconstyle} />
              <Typography className={classes.title}>{text}</Typography>
              <StarBorderOutlinedIcon className={classes.starIcon} />
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
      {
        open &&
        <UserModal closeModal={setOpen} title={title} />
    }
    </>
  );
};

export default ProjectCardItems;
