import React from 'react'
import CreatePlan from './CreatePlan'
import PlanListing from './PlanListing'
const Pricing = () => {
  return (
    <div>
        {/* <CreatePlan/> */}
        <PlanListing/>
    </div>
  )
}

export default Pricing