import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Container, TextField, Typography } from "@mui/material";
import SelectMember from '../Team/SelectMember'
const useStyle = makeStyles((theme) => ({
  main: {
    backgroundColor: "#F8F8F8",
    borderRadius: "5px",
    height: "850px",
  },
  usertitle: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    fontFamily: "Poppins !important",
    color: "#06283D",
    paddingTop: "20px",
  },

  form: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    justifyContent: "space-between",
  },

}));

const CreateTeamForm = () => {
  const classes = useStyle();
  return (
    <>
      <div className={classes.main}>
        <Container>
          <Typography className={classes.usertitle} >Create Team</Typography>
          <div className={classes.form}>
            <div style={{ width: '100%' }}>
              <SelectMember />
            </div>
          </div>


        </Container>
      </div>
    </>
  );
};

export default CreateTeamForm;
